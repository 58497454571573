import { graphql, StaticQuery } from "gatsby"
import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { SlideDown } from "react-slidedown"
import "react-slidedown/lib/slidedown.css"
import DropIcon from "../../assets/images/dropdown-icon.svg"
import "../../styles/component/plans-new.scss"
import Buttons from "../common/button"
import SVGIcon from "../common/SVGIcon"
import PricingOdometer from "./pricing-odometer"

function Plans(props) {
  const [currentPlan, setCurrentPlan] = useState(1000)

  const handleCurrentPlan = index => {
    if (index === currentPlan) {
      setCurrentPlan(1000)
    } else {
      setCurrentPlan(index)
    }
  }

  const popularTag = value => {
    if (value === "Yes") {
      return <div className="tag fw-bold">MOST POPULAR</div>
    } else {
      return <div className="tag-empty"></div>
    }
  }

  const currentItem = (isMonthly, month, annual) => {
    return isMonthly ? month : annual
  }

  const setCurrencyValue = (
    isMonthly,
    currency,
    m,
    a,
    eurom,
    euroa,
    gbpm,
    gbpa,
    audm,
    auda
  ) => {
    if (isMonthly && currency === "USD") {
      return m
    }

    if (!isMonthly && currency === "USD") {
      return a
    }

    if (isMonthly && currency === "EURO") {
      return eurom
    }
    if (!isMonthly && currency === "EURO") {
      return euroa
    }
    if (isMonthly && currency === "GBP") {
      return gbpm
    }
    if (!isMonthly && currency === "GBP") {
      return gbpa
    }
    if (isMonthly && currency === "AUD") {
      return audm
    }
    if (!isMonthly && currency === "AUD") {
      return auda
    }
  }

  return (
    <div className="plans">
      <StaticQuery
        query={graphql`
          query pricingListNew {
            SuperOps {
              pricingLists(
                orderBy: planOrder_ASC
                where: { page: "pricingnew", version: "v2" }
              ) {
                page
                planName
                planOrder
                features
                planDropdown
                monthlyPrice
                monthlyPriceDescription
                annualPrice
                annualPriceDescription
                endPoints
                isPopular
                description
                euroMonthlyPrice
                euroAnnualPrice
                gbpMonthlyPrice
                gbpAnnualPrice
                audMonthlyPrice
                audAnnualPrice
              }
            }
          }
        `}
        render={data => (
          <Row>
            {data.SuperOps.pricingLists.map((pl, plin) => {
              const {
                planName,
                planDropdown,
                planOrder,
                features,
                monthlyPrice,
                monthlyPriceDescription,
                annualPrice,
                annualPriceDescription,
                endPoints,
                isPopular,
                description,
                euroMonthlyPrice,
                euroAnnualPrice,
                gbpMonthlyPrice,
                gbpAnnualPrice,
                audMonthlyPrice,
                audAnnualPrice,
              } = pl
              return (
                <>
                  <Col md={6} lg={3} className="wrap">
                    <center
                      style={{ marginTop: isPopular === "Yes" ? "-27px" : "" }}
                    >
                      {popularTag(isPopular)}
                    </center>

                    <div
                      className={`content position-relative `}
                      style={{
                        border:
                          isPopular === "Yes"
                            ? "1px solid #FF0055"
                            : "1px solid #e0e0e0",
                      }}
                    >
                      <div>
                        <h5 className="name fw-bold"> {planDropdown} </h5>
                        <p className="excerpt"> {features} </p>
                        <div className="price-wrap">
                          <div className="left">
                            <p className="top">{props.currencySymbol}</p>
                            <p className="bottom">{props.currency}</p>
                          </div>
                          <div className="right">
                            <PricingOdometer
                              price={setCurrencyValue(
                                props.isMonthly,
                                props.currency,
                                monthlyPrice,
                                annualPrice,
                                euroMonthlyPrice,
                                euroAnnualPrice,
                                gbpMonthlyPrice,
                                gbpAnnualPrice,
                                audMonthlyPrice,
                                audAnnualPrice
                              )}
                            />
                          </div>
                        </div>
                        <div
                          className={`details p14 ${planName === "solo" &&
                            "price-solo-details"}`}
                        >
                          <p>
                            {currentItem(
                              props.isMonthly,
                              monthlyPriceDescription,
                              annualPriceDescription
                            )}
                          </p>
                        </div>
                        <div className="points p14">
                          <p className="sp-o">
                            Endpoints:{" "}
                            {!!endPoints ? (
                              <span>{endPoints}/tech </span>
                            ) : (
                              <span>-</span>
                            )}
                          </p>
                        </div>
                        <div className="pricing-beta-btn">
                          <Buttons
                            link="/signup"
                            theme={`secondary md w-100 mt16`}
                            text="START FOR FREE"
                          />
                        </div>
                        <div className="d-dsk">
                          <div className="line"> </div>
                        </div>
                      </div>

                      <div className="pricing-feature-dsk ">
                        {props.featureGroup.map((fl, i) => {
                          return (
                            <div
                              className={`pricing-plans-content-wrap ${i == 0 &&
                                "mt0"}`}
                            >
                              {props.newPricingLists
                                .filter(list => fl === list.featureGroup)
                                .map((p, i) => {
                                  return (
                                    <>
                                      <div className="price-icon">
                                        {p["plan" + planOrder] === "Text" ? (
                                          p.featureName ===
                                          "Additional Endpoints" ? (
                                            <p className="m-0">
                                              <span className="pint-tag">
                                                {props.currencySymbol}
                                                {props.epCostStatic}
                                              </span>
                                              /150 endpoints
                                            </p>
                                          ) : (
                                            <p className="m-0">
                                              <span className="pink-tag text-strike">
                                                {props.currencySymbol}
                                                {props.ndCostStrike}
                                              </span>{" "}
                                              {props.currencySymbol}
                                              {props.ndCost}/20 devices
                                            </p>
                                          )
                                        ) : p["plan" + planOrder] === "Yes" ? (
                                          <SVGIcon name="pricing/pricing-yes" />
                                        ) : (
                                          <SVGIcon
                                            name="pricing/pricing-not"
                                            className={
                                              p["plan" + planOrder]
                                                ? ""
                                                : "fade-out-effect"
                                            }
                                          />
                                        )}
                                      </div>
                                    </>
                                  )
                                })}
                            </div>
                          )
                        })}

                        {planName === "solo" ? (
                          <div className="pricing-solo-link">
                            <a href="/pricing/free-psa-rmm-solo-plan">
                              learn more about Solo plan
                            </a>
                          </div>
                        ) : null}
                      </div>

                      <div className="pricing-feature-mob mt16">
                        <SlideDown>
                          {plin === currentPlan ? (
                            <>
                              {props.featureGroup.map((fl, i) => {
                                const group = props.newPricingLists.filter(
                                  list =>
                                    fl === list.featureGroup &&
                                    list[planDropdown] === "Yes"
                                )
                                const featureWithNochar = fl.replace(
                                  /[-_:.]/g,
                                  " "
                                )
                                return (
                                  <>
                                    <div className="p16">
                                      {group.length != 0 && (
                                        <p className="feature-parent">
                                          {featureWithNochar}
                                        </p>
                                      )}
                                      {props.newPricingLists
                                        .filter(
                                          list => fl === list.featureGroup
                                        )
                                        .map((p, i) => {
                                          return (
                                            <>
                                              <div className="p14 prelative">
                                                {p["plan" + planOrder] ===
                                                  "Yes" && (
                                                  <>
                                                    <div className="d-flex feature-wrapper">
                                                      <p
                                                        className={`feature ${p.tooltip &&
                                                          "text-pink"}`}
                                                      >
                                                        {p.featureName}
                                                      </p>
                                                      {p.newFeature ===
                                                        "Yes" && (
                                                        <p className="new-feature ">
                                                          NEW
                                                        </p>
                                                      )}
                                                    </div>
                                                    {p.tooltip && (
                                                      <div
                                                        className="tip-icon"
                                                        tooltip-text={p.tooltip}
                                                      >
                                                        <SVGIcon name="pricing/tooltip" />
                                                      </div>
                                                    )}
                                                  </>
                                                )}
                                              </div>
                                            </>
                                          )
                                        })}
                                    </div>
                                  </>
                                )
                              })}
                              <div
                                style={{
                                  display: planName !== "solo" && "none",
                                }}
                                className="pricing-solo-link"
                              >
                                <a href="/pricing/solo-plan">
                                  learn more about Solo plan
                                </a>
                              </div>
                            </>
                          ) : null}
                        </SlideDown>

                        <div
                          className={`pr-pl-btn ${plin === currentPlan &&
                            "active"}`}
                          onClick={() => handleCurrentPlan(plin)}
                          onKeyDown={() => handleCurrentPlan(plin)}
                          role="button"
                          tabIndex={0}
                        >
                          <center className="p14">
                            <p>
                              {plin === currentPlan ? (
                                <> view less </>
                              ) : (
                                <> view features </>
                              )}
                              <img src={DropIcon} alt="dropdown" />
                            </p>
                          </center>
                        </div>
                      </div>
                    </div>
                  </Col>
                </>
              )
            })}
          </Row>
        )}
      />
    </div>
  )
}

export default Plans
