import axios from "axios"
import { graphql, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import "rc-slider/assets/index.css"
import React, { useEffect, useState } from "react"
import AnimatedNumber from "react-animated-number"
import { Button, ButtonGroup, Col, Container, Row } from "react-bootstrap"
import CustomAccordion from "../../../components/common/accordian"
import Buttons from "../../../components/common/button"
import CTA from "../../../components/common/CTA"
import Frame from "../../../components/common/frame"
import SVGIcon from "../../../components/common/SVGIcon"
import Navigation from "../../../components/navigation"
import Plans from "../../../components/pricing/plans-new"
import CTAThankYou from "../../../components/utility/cta_thank_you"
import Slide from "../../../components/utility/slide_content"
import "../../../styles/component/faq.scss"
import "../../../styles/component/pricing-faq.scss"
import "../../../styles/pricing-new.scss"

function Pricing() {
  const [isMonthly, setIsMonthly] = useState(false)

  const [ep, setEP] = useState(1)
  const [epPack, setEPPack] = useState(150)
  const [epCost, setEPCost] = useState(65)
  const [epCostStatic, setEPCostStatic] = useState(65)

  const [nd, setND] = useState(1)
  const [ndCost, setNDCost] = useState(20)
  const [ndCostStrike, setNDCostStrike] = useState(30)

  const [currency, setCurrency] = useState("USD")
  const [currencySymbol, setCurrencySymbol] = useState("$")

  useEffect(() => {
    const intercomShow = () => {
      if (typeof window.Intercom !== "undefined") {
        window.Intercom("show")
      }
    }

    document.getElementById("chatId2").onclick = function() {
      intercomShow()
    }

    document.querySelectorAll(
      ".faq .accordion .card-body p code"
    )[0].onclick = function(e) {
      intercomShow()
    }

    document.querySelectorAll(
      ".faq .accordion .card-body p code"
    )[1].onclick = function(e) {
      intercomShow()
    }

    document.querySelectorAll(
      ".faq .accordion .card-body p code"
    )[2].onclick = function(e) {
      intercomShow()
    }
  })

  const setCurrEPSymbol = (currency, epCost, symbol) => {
    setCurrency(currency)
    setEPCostStatic(epCost)
    setCurrencySymbol(symbol)
  }

  useEffect(() => {
    axios.get(process.env.IP_STACK_API).then(function(response) {
      if (
        response.data.continent_name === "Europe" &&
        response.data.country_name !== "United Kingdom"
      ) {
        setCurrEPSymbol("EURO", 65, "€")
        setNDCost(20)
        setNDCostStrike(30)
      } else if (response.data.country_name === "United Kingdom") {
        setCurrEPSymbol("GBP", 55, "£")
        setNDCost(15)
        setNDCostStrike(25)
      } else if (
        response.data.country_code === "AU" ||
        response.data.country_name === "Australia"
      ) {
        setCurrEPSymbol("AUD", 95, "$")
        setNDCost(30)
        setNDCostStrike(45)
      }
    })
  })

  useEffect(() => {
    setEPCost(ep * epCostStatic)
    setEPPack(ep * 150)
  }, [ep, epCostStatic])

  const changeEPValue = value => {
    if (value === "+") {
      setEP(ep + 1)
    } else {
      if (ep > 1) {
        setEP(ep - 1)
      }
    }
  }
  const changeNDValue = value => {
    if (value === "+") {
      setND(nd + 1)
    } else {
      if (nd > 1) {
        setND(nd - 1)
      }
    }
  }

  return (
    <>
      <StaticQuery
        query={graphql`
          query contentPricingNew {
            SuperOps {
              pages(where: { title: "Pricing" }) {
                title
                featureGroup
                pageBlock {
                  id
                  content {
                    html
                    text
                  }
                }
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
                ctaBoxes {
                  type
                  theme
                  heading {
                    html
                  }
                  description {
                    html
                  }
                  primaryButtonText
                  primaryButtonLink
                  secondaryButtonText
                  secondaryButtonLink
                }
              }
              newPricingLists(orderBy: featureOrder_ASC) {
                featureGroup
                featureName
                newFeature
                plan1
                plan2
                plan3
                plan4
                tooltip
                texts {
                  html
                }
              }
              faqs(where: { page: "Pricing" }) {
                category
                question
                answerrt {
                  html
                }
              }
            }
          }
        `}
        render={data => (
          <div className="pricing-wrap">
            {data.SuperOps.pages.map(page => {
              const {
                pageBlock,
                navigationBlock,
                seo,
                ctaBoxes,
                featureGroup,
              } = page
              return (
                <>
                  <Frame seo={seo}>
                    <header>
                      <Navigation links={navigationBlock} page="About us" />
                    </header>

                    <section className="main">
                      <div className="hero prelative mx-auto">
                        <Container className="pricing-hero-container text-center down-to-up-1">
                          {parse(pageBlock[0].content.html)}

                          <span className="p18">
                            {parse(pageBlock[1].content.html)}
                          </span>
                          <div className=" toggle-wrap mx-auto d-mob toggle-new">
                            <div class="switch-button">
                              <div
                                class={`switch-button-checkbox ${
                                  isMonthly ? "monthly-plan" : "yearly-plan"
                                }`}
                              >
                                {" "}
                              </div>

                              <div class="switch-button-label" for="">
                                <span
                                  class="switch-button-label-1"
                                  onClick={() => setIsMonthly(true)}
                                  style={{
                                    color: isMonthly ? "#ffff" : "#170426",
                                  }}
                                >
                                  MONTHLY
                                  {/* {parse(pageBlock[15].content.html)} */}
                                </span>
                                <span
                                  class="switch-button-label-2"
                                  onClick={() => setIsMonthly(false)}
                                  style={{
                                    marginLeft: "0px",
                                    color: !isMonthly ? "#ffff" : "#170426",
                                  }}
                                >
                                  ANNUALLY
                                  {/* {parse(pageBlock[16].content.html)} */}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="offer-wrap mt20 d-mob">
                            <span className="offer-tag">
                              {parse(pageBlock[17].content.text)}
                            </span>
                            <span> {pageBlock[18].content.text}</span>
                          </div>
                        </Container>
                      </div>

                      <div className="prelative d-dsk">
                        <Container className="plans-container down-to-up-1">
                          <Container className="Layout-container">
                            <Row>
                              <Col lg={3}>
                                {/* TOGGLE */}
                                <div className=" toggle-wrap mx-auto">
                                  <div class="switch-button fw-bold">
                                    <div
                                      class={`switch-button-checkbox ${
                                        isMonthly
                                          ? "monthly-plan"
                                          : "yearly-plan"
                                      }`}
                                    >
                                      {" "}
                                    </div>

                                    <div class="switch-button-label" for="">
                                      <span
                                        class="switch-button-label-1"
                                        onClick={() => setIsMonthly(true)}
                                        style={{
                                          color: isMonthly
                                            ? "#ffff"
                                            : "#170426",
                                        }}
                                      >
                                        MONTHLY
                                        {/* {parse(pageBlock[15].content.html)} */}
                                      </span>
                                      <span
                                        class="switch-button-label-2"
                                        onClick={() => setIsMonthly(false)}
                                        style={{
                                          marginLeft: "0px",
                                          color: !isMonthly
                                            ? "#ffff"
                                            : "#170426",
                                        }}
                                      >
                                        ANNUALLY
                                        {/* {parse(pageBlock[16].content.html)} */}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="offer-wrap mt20">
                                  <span className="offer-tag">
                                    {parse(pageBlock[17].content.text)}
                                  </span>
                                  <span> {pageBlock[18].content.text}</span>
                                </div>
                                {/* TOGGLE ENDS */}
                                <div className="feature-wrap">
                                  <span className="feature-head">FEATURES</span>
                                  {featureGroup.map(feature => {
                                    const featureWithNochar = feature.replace(
                                      /[-_:.]/g,
                                      " "
                                    )
                                    return (
                                      <>
                                        <p className="feature-group-title fw-bold">
                                          {featureWithNochar}
                                        </p>
                                        {data.SuperOps.newPricingLists
                                          .filter(
                                            list =>
                                              feature === list.featureGroup
                                          )
                                          .map((p, i) => {
                                            return (
                                              <div className="prelative">
                                                <div className="d-flex feature-wrapper">
                                                  <p
                                                    className={`feature-name ${
                                                      p.plan1 ? "" : "fw-bold"
                                                    } ${p.tooltip &&
                                                      "text-pink overflow"} `}
                                                    title={
                                                      p.tooltip && p.featureName
                                                    }
                                                  >
                                                    {p.featureName}
                                                  </p>
                                                  {p.newFeature === "Yes" && (
                                                    <p className="new-feature ">
                                                      NEW
                                                    </p>
                                                  )}
                                                </div>
                                                {p.tooltip && (
                                                  <div
                                                    className="tip-icon"
                                                    tooltip-text={p.tooltip}
                                                  >
                                                    <SVGIcon name="pricing/tooltip" />
                                                  </div>
                                                )}
                                              </div>
                                            )
                                          })}
                                      </>
                                    )
                                  })}
                                </div>
                              </Col>
                              <Col lg={9}>
                                <Plans
                                  isMonthly={isMonthly}
                                  currency={currency}
                                  currencySymbol={currencySymbol}
                                  featureGroup={featureGroup}
                                  newPricingLists={
                                    data.SuperOps.newPricingLists
                                  }
                                  epCostStatic={epCostStatic}
                                  ndCost={ndCost}
                                  ndCostStrike={ndCostStrike}
                                />
                              </Col>
                            </Row>
                          </Container>
                        </Container>
                      </div>

                      <div className="prelative d-mob">
                        <Container>
                          <Plans
                            isMonthly={isMonthly}
                            currency={currency}
                            currencySymbol={currencySymbol}
                            featureGroup={featureGroup}
                            newPricingLists={data.SuperOps.newPricingLists}
                          />
                        </Container>
                      </div>

                      <div className="extra-snmp mx-auto">
                        <Container className="">
                          <Row className="justify-content-center gap-24">
                            <div className="extra-points-pricing extra m-0">
                              <div className="heading">
                                {parse(pageBlock[12].content.html)}
                              </div>
                              <div className="extra-points-pricing-split d-flex align-items-center gap-8">
                                <div className="left d-flex flex-column gap-24">
                                  {" "}
                                  <span className="p14">
                                    {parse(pageBlock[13].content.html)}{" "}
                                  </span>
                                  <div className="button-group d-flex align-items-center gap-8">
                                    <ButtonGroup className="btn-grp">
                                      <Button
                                        className="lf"
                                        onClick={() => changeEPValue("-")}
                                      >
                                        -
                                      </Button>
                                      <Button className="middle">{ep}</Button>
                                      <Button
                                        className="rt"
                                        onClick={() => changeEPValue("+")}
                                      >
                                        +
                                      </Button>
                                    </ButtonGroup>
                                    <div className="cost-inline p13">
                                      {" "}
                                      <p className="m-0">
                                        x {currencySymbol}
                                        {epCostStatic}{" "}
                                      </p>{" "}
                                    </div>
                                  </div>
                                  <div className="cost-block">
                                    {" "}
                                    1 pack = 150 endpoints{" "}
                                  </div>
                                </div>
                                <div className="right">
                                  <div className="outer prelative d-flex flex-column gap-24">
                                    <h2 className="text-center">
                                      {" "}
                                      {currencySymbol}
                                      <AnimatedNumber
                                        stepPrecision={0}
                                        value={epCost}
                                      />{" "}
                                    </h2>

                                    <span className="p12 text-center">
                                      <p> for {epPack} endpoints/month </p>
                                    </span>
                                    <div className="mx-auto text-center">
                                      <Buttons
                                        theme="primary price-btn"
                                        link="/signup"
                                        text="BUY ENDPOINTS"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="extra-points-pricing snmp">
                              <div className="heading">
                                {parse(pageBlock[19].content.html)}
                              </div>
                              <div className="extra-points-pricing-split align-items-center d-flex gap-8">
                                <div className="left d-flex flex-column  gap-24">
                                  {" "}
                                  <span className="p14">
                                    {parse(pageBlock[20].content.html)}{" "}
                                  </span>
                                  <div className="button-group d-flex align-items-center gap-8">
                                    <ButtonGroup className="btn-grp">
                                      <Button
                                        className="lf"
                                        onClick={() => changeNDValue("-")}
                                      >
                                        -
                                      </Button>
                                      <Button className="middle">{nd}</Button>
                                      <Button
                                        className="rt"
                                        onClick={() => changeNDValue("+")}
                                      >
                                        +
                                      </Button>
                                    </ButtonGroup>
                                    <div className="cost-inline p13">
                                      {" "}
                                      <p className="m-0">
                                        x
                                        <span className="p-0">
                                          {" "}
                                          {currencySymbol}
                                          {ndCost}
                                        </span>
                                        {"/"}
                                        <span className="text-pink line-through">
                                          {currencySymbol}
                                          {ndCostStrike}
                                        </span>
                                      </p>{" "}
                                    </div>
                                  </div>
                                  <div className="cost-block">
                                    {" "}
                                    1 pack = 20 network devices{" "}
                                  </div>
                                </div>
                                <div className="right">
                                  <div className="outer prelative d-flex flex-column gap-24">
                                    <h2 className="text-center">
                                      {" "}
                                      {currencySymbol}
                                      <AnimatedNumber
                                        stepPrecision={0}
                                        value={nd * ndCost}
                                      />{" "}
                                    </h2>

                                    <span className="p12 text-center">
                                      <p>
                                        {" "}
                                        for {nd * ndCost} network devices/month{" "}
                                      </p>
                                    </span>
                                    <div className="mx-auto text-center">
                                      <Buttons
                                        theme="primary price-btn"
                                        link="/signup"
                                        text="GET NETWORK MONITORING"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Row>
                        </Container>
                      </div>

                      {/* <div className="extra p-0 mx-auto">
                        <Container className="p-0">
                          <center>
                            <Slide delay="200">
                              <div className="content prelative">
                                <SVGIcon
                                  name="pricing/spiral-black-ht"
                                  className="SpiralBlackHT position-absolute d-dsk"
                                />
                                <SVGIcon
                                  name="pricing/spiral-black-hb"
                                  className="SpiralBlackHB position-absolute d-dsk"
                                />
                                <SVGIcon
                                  name="pricing/data-green"
                                  className="DataGreen position-absolute d-dsk"
                                />
                                <SVGIcon
                                  name="pricing/harddisk-yellow"
                                  className="HarddiscYellow position-absolute d-dsk"
                                />
                                <Row className="extra-points-pricing">
                                  <Col lg={6} className="lft">
                                    {" "}
                                    {parse(pageBlock[12].content.html)}{" "}
                                  </Col>
                                  <Col lg={6} className="ryt">
                                    {" "}
                                    <div className="cover">
                                      <Row className="extra-points-pricing-split">
                                        <Col
                                          lg={6}
                                          className="left d-flex flex-column align-items-center"
                                        >
                                          {" "}
                                          <span className="p14">
                                            {parse(pageBlock[13].content.html)}{" "}
                                          </span>
                                          <div className="button-group">
                                            <ButtonGroup className="btn-grp">
                                              <Button
                                                className="lf"
                                                onClick={() =>
                                                  changeEPValue("-")
                                                }
                                              >
                                                -
                                              </Button>
                                              <Button className="middle">
                                                {ep}
                                              </Button>
                                              <Button
                                                className="rt"
                                                onClick={() =>
                                                  changeEPValue("+")
                                                }
                                              >
                                                +
                                              </Button>
                                            </ButtonGroup>
                                            <div className="cost-inline p13">
                                              {" "}
                                              <p>
                                                x {epCostStatic}
                                                {currencySymbol}{" "}
                                              </p>{" "}
                                            </div>
                                          </div>
                                          <div className="cost-block">
                                            {" "}
                                            1 pack = 150 end points{" "}
                                          </div>
                                        </Col>
                                        <Col lg={6} className="right">
                                          <div className="outer prelative">
                                            <h2>
                                              {" "}
                                              {currencySymbol}
                                              <AnimatedNumber
                                                stepPrecision={0}
                                                value={epCost}
                                              />{" "}
                                            </h2>

                                            <span className="p12">
                                              <p>
                                                {" "}
                                                manage {epPack} end points{" "}
                                              </p>
                                            </span>
                                            <div className="mt60 mx-auto text-center">
                                              <Buttons
                                                theme="primary"
                                                link="/signup"
                                                text="BUY END POINTS"
                                              />
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>{" "}
                                  </Col>
                                </Row>
                              </div>
                            </Slide>
                          </center>
                        </Container>
                      </div> */}

                      <div className="faq">
                        <Container className="Layout-container">
                          <Row className="justify-content-between">
                            <Col lg={4}>
                              {parse(pageBlock[9].content.html)}
                              {parse(pageBlock[10].content.html)}
                            </Col>
                            <Col lg={8}>
                              <div className="section">
                                <CustomAccordion items={data.SuperOps.faqs} />
                                <Row>
                                  <Col lg={5}>
                                    <h5 className="pricing-faq-title fw-bold">
                                      What if I have more questions?
                                    </h5>
                                  </Col>
                                  <Col lg={7}>
                                    <span className="p16">
                                      <p className="pricing-faq-desc">
                                        We're available round the clock to
                                        answer your questions and help you find
                                        the right plan.{" "}
                                        <span id="chatId2">Ping us</span> or
                                        drop us a line at sales@superops.com.
                                      </p>
                                    </span>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </div>

                      <div className="pricing-cta ">
                        <Slide delay="200">
                          <CTA data={ctaBoxes} />
                        </Slide>
                      </div>

                      {/* <div className="believe">
                      <center>
                        <section className="outer">
                          <Container>
                            <Slide delay="200">
                              {parse(pageBlock[11].content.html)}
                            </Slide>
                            <Slide delay="200">
                              <div className="inner">
                                <center>
                                  <section className="sp-reason bel-reason">
                                    <div className="content">
                                      <div className="column-container">
                                        <BelieveBlock />
                                      </div>
                                    </div>
                                  </section>
                                </center>
                              </div>
                            </Slide>
                          </Container>
                        </section>
                      </center>
                    </div> */}
                    </section>
                    <CTAThankYou />
                  </Frame>
                </>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default Pricing
